<script>
import { nextTick } from 'vue';

const projectsQuery = `query Projects(
    $type: [String] = "*",
    $srcset: [String!]! = [],
    $defaultWidth: Int,
    $defaultHeight: Int
) {
  entries(section: "project", relatedToCategories: {slug: $type}) {
    id
    title
    ... on project_default_Entry {
      location
      objectType {
        title
      }
      leadImage @transform(
        width: $defaultWidth,
        height: $defaultHeight,
        format: "webp",
        mode: "crop",
        position: "center-center"
      ) {
        title
        url @transform(width: $defaultWidth, height: $defaultHeight, format: "jpg")
        srcset(sizes: $srcset)
      }
      ongoing
      url
    }
  }
}`;
const projectsContainer = document.querySelector('#projects');
const radios = document.querySelector('#project-type-selection').elements['project-filter'];

const srcset = Array.from({length: 8}, (_, i) => 240 + i * 100 + 'w').reverse();

export default {
  data() {
    return {
      projects: [],
      type: null
    }
  },
  props: ['auth-token'],
  mounted() {
    this.load(null, initialData);
  },
  methods: {
    load(type = '*', data) {
      const prevAmount = this.projects.length;

      const dataPromise = data ? Promise.resolve(data) : fetch('/projekte/api', {
        method: 'Post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': projectsContainer.dataset.authToken,
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          query: projectsQuery, variables: {
            type: type || '*',
            defaultWidth: 540,
            defaultHeight: 360,
            srcset
          }
        })
      }).then(r => r.json());

      return dataPromise
          .then(data => {
            projectsContainer.getAnimations().forEach(anim => anim.cancel());

            const prevHeight = projectsContainer.offsetHeight + 'px';

            projectsContainer.querySelectorAll('.entry').forEach(el => {
              el.dataset.width = el.clientWidth;
              el.dataset.height = el.clientHeight;
              el.dataset.left = el.offsetLeft;
              el.dataset.top = el.offsetTop;
            })
            this.projects = data.data.entries;

            window.requestAnimationFrame(() => {
              projectsContainer.animate([
                {height: prevHeight},
                {height: projectsContainer.offsetHeight + 'px'}
              ], {
                duration: Math.abs(this.projects.length - prevAmount) * 100
              }).onfinish = () => document.querySelector('#hide-footer')?.remove();
            })
          })
    },
    onBeforeLeave(el) {
      el.style.width = `${el.dataset.width}px`;
      el.style.height = `${el.dataset.height}px`;
      el.style.left = `${el.dataset.left}px`;
      el.style.top = `${el.dataset.top}px`;
    },
    onBeforeEnter(el) {
      el.style.transitionDelay = `${(parseFloat(el.dataset.index) | 0) * 0.1}s`;
    },
    onAfterEnter(el) {
      el.style.transitionDelay = null;
    },
  }
}
</script>

<template>
  <transition-group @before-leave="onBeforeLeave" @before-enter="onBeforeEnter" @after-enter="onAfterEnter">
    <article v-for="(project, index) in projects" :key="project.id" class="entry"
             :data-index="index">
      <a :href="project.url + (type? `?type=${type}` : '')">
        <header>
          <picture>
            <source type="image/webp" :srcset="project.leadImage[0].srcset"
                    sizes=" (min-width: 1440px) calc((100vw - 168px) / 4),
                            (min-width: 1024px) calc((100vw - 144px) / 3),
                            (min-width:  600px) calc((100vw - 120px) / 2),
                                                calc((100vw -  64px) / 1)"/>
            <img :src="project.leadImage[0].url" :alt="project.leadImage[0].title"/>
          </picture>
        </header>
        <p class="location">{{ project.location }}</p>
        <h2 class="entry-title">{{ project.title }}</h2>
        <ul class="types">
          <li v-for="type in project.objectType">
            <wbr/>
            {{ type.title }}
          </li>
          <li v-if="project.ongoing">Laufendes Projekt</li>
        </ul>
      </a>
    </article>
  </transition-group>
</template>